import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import WhatWeBelieve from "./pages/WhatWeBelieve";
// import ServiceGame from "./container/service/ServiceGame";
// import ServiceMobile from "./container/service/ServiceMobile";
// import ServiceUi from "./container/service/ServiceUi";
// import ServiceMarketing from "./container/service/ServiceMarketing";
// import ServiceDevelopment from "./container/service/ServiceDevelopment";
// import ServiceTest from "./container/service/ServiceTest";
import Test from "./pages/Test";
import Game from "./pages/Game";
import Mobile from "./pages/Mobile";
import UiAndUx from "./pages/UiAndUx";
import Marketing from "./pages/Marketing";
// import Development from "./pages/Development";
const HomeOne = lazy(() => import("./pages/HomeOne"));
const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Work = lazy(() => import("./pages/Work"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Contact = lazy(() => import("./pages/Contact"));
const Development = lazy(() => import("./pages/Development"));


function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: 'ease',
    });
    AOS.refresh();

  }, [])
  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne />} />
            <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} element={<HomeOne />} />
            <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} element={<HomeTwo />} />
            <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} element={<HomeThree />} />
            <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About />} />
            <Route path={`${process.env.PUBLIC_URL + "/service"}`} element={<Service />} />
            <Route path={`${process.env.PUBLIC_URL + "/test"}`} element={<Test />} />
            <Route path={`${process.env.PUBLIC_URL + "/game"}`} element={<Game />} />
            <Route path={`${process.env.PUBLIC_URL + "/mobile"}`} element={<Mobile />} />
            <Route path={`${process.env.PUBLIC_URL + "/uiandux"}`} element={<UiAndUx />} />
            <Route path={`${process.env.PUBLIC_URL + "/marketing"}`} element={<Marketing />} />
            <Route path={`${process.env.PUBLIC_URL + "/development"}`} element={<Development />} />
            <Route path={`${process.env.PUBLIC_URL + "/believe"}`} element={<WhatWeBelieve />} />
            <Route path={`${process.env.PUBLIC_URL + "/work"}`} element={<Work />} />
            <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} element={<WorkDetails />} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} element={<BlogGrid />} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={<BlogClassic />} />
            <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} element={<BlogTag />} />
            <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} element={<BlogCategories />} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} element={<BlogDetails />} />
            <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact />} />
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}

export default App;
