import { useState, useEffect, useRef } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from 'parallax-js';


const MobileOne = () => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            {/* <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}> */}
            <div className="container">

                <SectionTitle
                    // headingOption="fz-32"
                    title="Mobile Application Development"
                // subTitle="We produce beautifully crafted creative solutions that transcend business goals.
                // We provide the exceptional service we’d want to experience ourselves!"
                />

            </div>
            <div className="container">
                <div className="row">
                    <h3 style={{ marginBottom: 20, marginTop: 50, color: "red" }}>Android Mobile Application Development</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Android Phones holds 80 per cent of the market share, thus ruling the world. While the usage of Android phones is increasing, the craze for android mobile apps is also being improved every year.
                    </p>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS, one of the leading Android mobile application development companies, helps turn your idea into a reality by creating an amazing app.
                    </p>





                    <h3 style={{ marginTop: 60 }}>Why Android Mobile App</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Wide Usage
                        </li>
                        <li>
                            Easy Installation
                        </li>
                        <li>
                            Easy Customisation
                        </li>
                        <li>
                            Great Deployment
                        </li>
                        <li>
                            Security
                        </li>
                        <li>
                            High ROI
                        </li>
                        <li>
                            Low Cost
                        </li>

                    </ul>

                    <h3 style={{ marginTop: 60, }}>Android Mobile App Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>Android  TECHDEETS android app development agency provides varied services, and a few are mentioned below:</p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Custom Apps
                        </li>
                        <li>
                            Game Apps
                        </li>
                        <li>
                            eCommerce Apps
                        </li>
                        <li>
                            Utility Apps
                        </li>
                        <li>
                            Enterprise Apps
                        </li>
                        <li>
                            Education Apps
                        </li>
                        <li>
                            Travel Apps
                        </li>
                        <li>
                            Multimedia Apps
                        </li>
                        <li>
                            Financial Apps
                        </li>
                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why TECHDEETS Android App Development Services?</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We can find many android app development companies on the internet. However, only a few companies can provide you with the best services, and TECHDEETS is one among them.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20, }}>Unique Approach</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our approach towards the project is quite different from others. We work in a way that only produces the best quality apps and nothing else. We understand that the requirements of each client are different and so are the apps and their working strategies.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Technical Excellence</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our developers’ team is technically sound. With their experience, they can work on any complex project with ease and deliver it to the client on time.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Positive Feedbacks</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Most of the clients for whom we worked with have given us positive feedback, and we feel it as our success!
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Transparent Work Approach</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We engage our clients throughout the app development process, thereby delivering solutions per the client’s requirements. We use email, Skype, GoogleMeet, Zoom, phone, and other mediums for regular communication with our clients.
                    </p>




                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 me-0">

                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    )
}

export default MobileOne;