import { useState, useEffect, useRef } from "react";

import Parallax from 'parallax-js';


const BelieveOne = () => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">
                    <h2 style={{ marginBottom: 20 }}>What we Believe?</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS believes in hard work, transparency, and most importantly
                        client satisfaction. Clients are always our priority among other aspects.
                        With a unique approach, hard work, and commitment, TECHDEETS has
                        transformed into a business giant all through the years. From increasing
                        the client base to achieving a 100 per cent success rate, it has taken a lot of
                        efforts to reach the heights we are in.
                        TECHDEETS achieved success in a short span of time because of its
                        business transformation process that includes strategic planning, data
                        awareness, and customer orientation. We always strive hard to serve our
                        clients better and bigger!
                    </p>

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">

                            {/* <SectionTitleTwo 
                                subTitle="Web design and digital marketing"
                                title="We think strategy, UX design, and web development"
                            /> */}

                            {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                    onChange={onVisibilityChange}
                                                    offset={{ top: 10 }}
                                                    delayedCall
                                                    >
                                                    <CountUp end={didViewCountUp ? 110 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Happy Clients</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 me-0">
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BelieveOne;

