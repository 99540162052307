import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom-180">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-3.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-4.jpg"} alt="" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">

                                    <img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" />
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            {/* <SectionTitleTwo
                                // subTitle="Our solutions begin with brand research"
                                title="Mission"
                            /> */}
                            <h3>Mission</h3>

                            <p style={{ fontSize: 20, fontWeight: 400, }}>The mission of TECHDEETS is to build the websites and mobile apps that
                                are unique and responsive, with all features, to satisfy the clients to the
                                fullest.</p>
                            <hr />
                            <h3>Vision</h3>
                            <p style={{ fontSize: 20, fontWeight: 400, }}>The vision of TECHDEETS is to become a top web and digital solution
                                provider globally to enhance your business growth. We build a strong
                                relationship with our clients and gain their trust to make this happen.</p>
                            {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>Get Started</Link> */}
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}

export default AboutFive;
