import { useState, useEffect, useRef } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from 'parallax-js';


const DevelopmentOne = () => {
    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            {/* <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}> */}
            <div className="container">

                <SectionTitle
                    // headingOption="fz-32"
                    title="Web Development"
                // subTitle="We produce beautifully crafted creative solutions that transcend business goals.
                // We provide the exceptional service we’d want to experience ourselves!"
                />

            </div>
            <div className="container">
                <div className="row">
                    <h3 style={{ marginBottom: 20, marginTop: 50, color: "red" }}>React JS</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>React JS is one of the focus areas of TECHDEETS front-end development segment.
                    </p>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>Build an awesome, fast, robust, and flexible UI with the best team of React JS web development experts! Reach out to hire experienced React developers for your esteemed project today.</p>



                    <h3 style={{ marginTop: 60 }}>Why ReactJs?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Highly Efficient
                        </li>
                        <li>
                            Open Source
                        </li>
                        <li>
                            No dependencies
                        </li>
                        <li>
                            Stable Code
                        </li>
                        <li>
                            Amazingly Versatile
                        </li>
                        <li>
                            Responsive UI
                        </li>
                        <li>
                            Component Reusability
                        </li>
                        <li>
                            Easy to Learn
                        </li>
                        <li>
                            Ability to Reuse Code
                        </li>
                        <li>
                            Developer Tools
                        </li>
                        <li>
                            SEO-Compatible
                        </li>
                        <li>
                            Easy Testability
                        </li>


                    </ul>

                    <h3 style={{ marginTop: 60, }}>ReactJS Development Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, we offer a range of ReactJS development services that include:</p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            ReactJS web app development
                        </li>
                        <li>
                            ReactJS mobile app development
                        </li>
                        <li>
                            ReactJS Plugin Development
                        </li>
                        <li>
                            Customized development
                        </li>
                        <li>
                            ReactJS Consulting
                        </li>
                        <li>
                            Migration
                        </li>
                        <li>
                            Maintenance
                        </li>

                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why choose TECHDEETS ReactJS Development Services</h2>

                    <h4 style={{ marginTop: 30, marginBottom: 20 }}>Agile Development Approach </h4>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>From start to end, we strive to deliver the best solution in shorter sprints. We practice a secure, agile development approach all through the project.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Dedicated React JS Development Team</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our experts will help in building solutions using an innovative approach. With the development of intuitive UI, robust architectures, and reliable portals, our experts will deploy comprehensive and organized React JS solutions with world-class features and functionalities.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Security</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We build secure end-to-end React apps. We incorporate the best security practices like seamlessly integrating Jscrambler, using advanced techniques.
                    </p>

                    <h2 style={{ marginBottom: 20, }}>Transparent Work Approach</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We engage our clients throughout the app development process, thereby delivering solutions per the client’s requirements. We use email, Skype, GoogleMeet, Zoom, phone, and other mediums for regular communication with our clients.
                    </p>
                    <h2 style={{ marginBottom: 20, }}>Client Satisfaction</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our designers, developers, project managers and quality analysts – all strive for customer satisfaction. We deliver ReactJS development services that align with our clients’ needs.
                    </p>

                    <h2 style={{ marginBottom: 20, }}>Competitive Rates</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TechDeets, we offer the most competitive rates on the market. Our customized services meet the different budget needs of our clients from across the globe.
                    </p>



                </div>



            </div>
        </div>
    )
}

export default DevelopmentOne;