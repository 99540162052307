import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { Card } from "react-bootstrap";

const AboutFour = () => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">
                    <h2 style={{ marginBottom: 20 }}>About Techdeets</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We build brands using latest technologies to make your dream come true.
                        We established our web development and digital marketing company in
                        2018 and started providing services that are one-call reach to our clients.
                        Our goal is to make your business survive in this competitive internet
                        world. </p>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>Over the years, we have worked for thousands of clients on different
                        projects including web designing, developing, and digital marketing. Our
                        services are available for small, medium, and large scale industries as well.
                        We understand that clients have a reason to start own website, either for
                        money or to grow as a brand that remains in people minds forever. So, we
                        always have an initial meet with a client over a phone or in-person to
                        understand their goal along with needs. </p>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>We deliver the project to the client with more than what they expect, and
                        this made us earn huge fame in short time. Now, we have been providing
                        E-Commerce, Content Writing, Content Marketing, Corporate Training, Product R&D along with Web Development and Digital Marketing
                        services. </p>
                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">

                            {/* <SectionTitleTwo 
                                subTitle="Web design and digital marketing"
                                title="We think strategy, UX design, and web development"
                            /> */}

                            {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                    onChange={onVisibilityChange}
                                                    offset={{ top: 10 }}
                                                    delayedCall
                                                    >
                                                    <CountUp end={didViewCountUp ? 110 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Happy Clients</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 me-0">
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFour;
