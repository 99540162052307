import { useState, useEffect, useRef } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from 'parallax-js';


const UiOne = () => {

    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            {/* <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}> */}
            <div className="container">

                <SectionTitle
                    // headingOption="fz-32"
                    title="UI & UX"
                // subTitle="We produce beautifully crafted creative solutions that transcend business goals.
                // We provide the exceptional service we’d want to experience ourselves!"
                />

            </div>
            <div className="container">

                <div className="row">
                    <h1 style={{ marginBottom: 50, marginTop: 30, color: "red", textAlign: "center" }}>HTML</h1>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>HTML has a significant share in building the World Wide Web. At TECHDEETS, we have a team of HTML experts who have served thousands of clients all over the world for varied industries. TECHDEETS builds robust and scalable apps that communicate with rich content and enhance user experience.

                    </p>



                    <h3 style={{ marginTop: 60 }}>Why HTML?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Easy to use
                        </li>
                        <li>
                            Supported by all browsers
                        </li>
                        <li>
                            Simple to Edit
                        </li>
                        <li>
                            Easy Integration
                        </li>
                        <li>
                            Cross-browser Compatibility
                        </li>
                        <li>
                            Agile
                        </li>
                        <li>
                            User Friendly
                        </li>



                    </ul>

                    <h3 style={{ marginTop: 60, }}>HTML Development Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS provides user-friendly and responsive HTML development services to its clients. With great experience, our HTML experts develop a website according to the requirements of the client. Our HTML Services include:
                    </p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            HTML5 Responsive Web Design
                        </li>
                        <li>
                            PSD to HTML5 Conversion
                        </li>
                        <li>
                            Custom HTML5 Development
                        </li>
                        <li>
                            HTML5 Design Services
                        </li>
                        <li>
                            HTML5/CSS3+SASS Coding
                        </li>
                        <li>
                            Website Re-design & Maintenance
                        </li>


                    </ul>
                    <h2 style={{ marginTop: 40, textAlign: "center" }}>Why TECHDEETS HTML Development Services?</h2>

                    <h2 style={{ marginTop: 30, marginBottom: 20 }}>Meets Customer Demands: </h2>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS HTML professionals discuss the project with the clients initially to understand their ideas and requirement. According to their demands, they plan a strategy to develop a website or an app.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>100% Transparency</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>As we believe in transparency, we won’t hide anything from you, even the minute details. From explaining how we work to how we price our services, we explain everything to our clients.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>No Hidden Charges</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We make you understand for what you are paying! The price is fixed, and there would be no hidden charges until the project is submitted.
                    </p>


                    <h1 style={{ marginBottom: 50, marginTop: 30, color: "red", textAlign: "center" }}>CSS</h1>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>It only takes a few seconds for your visitors to move back from your website, which increases the bounce rate, resulting in the reduction of business growth. The good news is that there’s a way to solve this problem. All you need to have is a super user-friendly website, and TECHDEETS will do it for you!

                    </p>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>
                        Empower your business and take your application to the next level with TECHDEETS’s CSS development services. Our developers use the most advanced features of CSS to build an app that is robust, effective, and efficient.
                    </p>

                    <h3 style={{ marginTop: 60 }}>Why CSS?</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>To choose CSS development for your app, there’s not one, but are many reasons!

                    </p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Greater design consistency
                        </li>
                        <li>
                            More formatting options
                        </li>
                        <li>
                            Simple code
                        </li>
                        <li>
                            Easy maintenance
                        </li>
                        <li>
                            Good accessibility
                        </li>
                        <li>
                            SEO benefits
                        </li>
                        <li>
                            Rapid download times
                        </li>



                    </ul>

                    <h3 style={{ marginTop: 60, }}>CSS Development Services</h3>


                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            PSD to Responsive HTML5/CSS3 Conversion
                        </li>
                        <li>
                            PSD to Responsive CMS Theme Conversion
                        </li>
                        <li>
                            PSD to Responsive Newsletter Conversion
                        </li>
                        <li>
                            HTML5/CSS3 Website Development
                        </li>
                        <li>
                            HTML5/CSS3 Mobile App Development
                        </li>
                        <li>
                            CSS3 Preprocessor Programming
                        </li>
                        <li>
                            CSS Responsive Web Design
                        </li>
                        <li>
                            CSS3 Framework Extension
                        </li>
                        <li>
                            CSS Front-End Development
                        </li>
                        <li>
                            CSS3 Rich Audio Visuals Integration
                        </li>
                        <li>
                            Animated HTML5 Banner Development
                        </li>
                        <li>
                            PSD to Responsive eCommerce Theme Conversion
                        </li>


                    </ul>


                    <h2 style={{ marginTop: 30, marginBottom: 20 }}>Why TECHDEETS CSS Services? </h2>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>There are a lot of reasons why you should choose TECHDEETS CSS development services over others. Here, we are giving you a few among them.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Latest Tools & Techniques</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We use the latest CSS3 tools, updated W3C standards, and other industry best practices to provide you with the best CSS solutions.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>We make it a Brand</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our designers and developers together follow a pre-planned work process and make your website look great, thus helping in brand enhancement.
                    </p>
                    <h2 style={{ marginBottom: 20 }}>Security & Transparency</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS provides both secure and transparent services. We communicate every detail with our clients before the project gets started.
                    </p>


                    <h1 style={{ marginBottom: 50, marginTop: 30, color: "red", textAlign: "center" }}>Bootstrap</h1>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Bootstrap is a front-end framework that is faster and responsive. TECHDEETS is the prominent Bootstrap service provider with a team of Bootstrap specialists who earlier worked on varied projects.

                    </p>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>
                        Are you ready to avail our Bootstrap development services? Then contact us to hire an individual or a team of front-end developers to help you!
                    </p>

                    <h3 style={{ marginTop: 60 }}>Why Bootstrap?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Faster & easier to develop
                        </li>
                        <li>
                            Responsive
                        </li>
                        <li>
                            Efficient
                        </li>
                        <li>
                            Many pre-styled components & Modules
                        </li>
                        <li>
                            Multi-Browser Compatibility
                        </li>
                        <li>
                            Amazing Grid Framework
                        </li>
                        <li>
                            Easy Integration
                        </li>
                        <li>
                            Standardised HTML Syntax
                        </li>
                        <li>
                            Frequent Updates
                        </li>
                        <li>
                            Rich Documentation
                        </li>



                    </ul>

                    <h3 style={{ marginTop: 60, }}>Bootstrap Development Services</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS, the best Bootstrap development company, is here for you to provide the following Bootstrap development services:</p>
                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Bootstrap application development
                        </li>
                        <li>
                            Responsive Template Development
                        </li>
                        <li>
                            Custom Bootstrap development services
                        </li>
                        <li>
                            Bootstrap Version Upgrade
                        </li>
                        <li>
                            Bootstrap Migration Services
                        </li>


                    </ul>


                    <h2 style={{ marginTop: 30, marginBottom: 50 }}>Why TECHDEETS Bootstrap Development Services? </h2>

                    <h2 style={{ marginTop: 30, marginBottom: 20 }}>Great User Experience</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>People’ interests are changing from time to time, and so they need an improved web and mobile experience. At TECHDEETS, we have a team of in-house front-end developers who have both experience and expertise. They walk through your project and deliver only the best output before the deadline.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Well-defined Process</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We follow a defined development process to reduce the risks and uncertainties, thus ensuring the perfect output.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Flawless & Cost-effective</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>With a good expertise, our developers provide a range of Bootstrap development services that are effective and flawless. Surprisingly, TECHDEETS provides these best services for an affordable price.
                    </p>


                </div>


            </div>
        </div>
    )
}

export default UiOne;