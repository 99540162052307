import { useState, useEffect, useRef } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from 'parallax-js';


const ServiceGame = () => {

    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            {/* <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}> */}
            <div className="container">

                <SectionTitle
                    // headingOption="fz-32"
                    title="Gaming"
                // subTitle="We produce beautifully crafted creative solutions that transcend business goals.
                // We provide the exceptional service we’d want to experience ourselves!"
                />

            </div>
            <div className="container">
                <div className="row">
                    <h3 style={{ marginBottom: 20, marginTop: 50, color: "red" }}>Unity 3D Services</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Unity 3D is a popular game development framework that has occupied a prominent place in mobile game development. Understanding the popularity Unity 3D has gained through the years, TECHDEETS hired a team of expert Unity 3D developers for its clients.
                    </p>







                    <h3 style={{ marginTop: 60 }}>Why Unity 3D?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            One-time Development
                        </li>
                        <li>
                            Multi-Platform Deployment
                        </li>
                        <li>
                            Impeccable Graphic Integration
                        </li>
                        <li>
                            Low Development Cost
                        </li>
                        <li>
                            Uses Advanced Tools
                        </li>
                        <li>
                            Secure
                        </li>


                    </ul>

                    <h3 style={{ marginTop: 60, }}>Unity 3D Development Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, our developers provide various Unity 3D development services and here are a few among them.</p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Unity 3D Cross-Platform Apps Development
                        </li>
                        <li>
                            2D and 3D Games Development
                        </li>
                        <li>
                            Augmented & Virtual Reality Apps Development
                        </li>
                        <li>
                            Console Games Development (Xbox, PS, Wii)
                        </li>
                        <li>
                            Game Application Maintenance and Support
                        </li>

                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why TECHDEETS Unity 3D Development Services?</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>If you want an appealing and user-friendly game application, TECHDEETS is the best option for you. Let’s see why!
                    </p>

                    <h2 style={{ marginBottom: 20, }}>Develops Interactive Games</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Our Unity 3D developers build interactive game apps that are accessible both on web and mobile. They develop high-end AR and VR games.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Business Growth Opportunities</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, our Unity 3D professionals develop an app in the way that you can showcase your products in a real 3D environment, thus encouraging your business growth.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Advanced Tools</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We aim to provide a realistic gaming experience to the players, so we use advanced tools and technologies. Our proficient 3D Unity developers are well-versed with all the latest tools that enhance the gaming experience.
                    </p>
                    <br />
                    <br />
                    <br />
                    <h2 style={{ marginBottom: 20, marginTop: 90, color: "red" }}>Unreal Engine</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS is one of the accepted Unreal Engine Game Development Companies. The expert team of TECHDEETS offers innovative Unreal Engine solutions and uses UE4 expertise to enhance your business opportunities. Unreal Engine is quite popular for building 3D video games, 3D product videos, technology simulations and more.
                    </p>

                    <h3 style={{ marginTop: 60 }}>Why Unreal Engine?</h3>
                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Photorealistic Graphics
                        </li>
                        <li>
                            3D Visualisation
                        </li>
                        <li>
                            Platform Support
                        </li>
                        <li>
                            Secure
                        </li>


                    </ul>


                    <h3 style={{ marginTop: 60, }}>Unreal Engine Game App Development Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS offers various Unreal Engine Game Application Development Solutions across different Industries. Our Services include:</p>


                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Desktop Solutions
                        </li>
                        <li>
                            Mobile and Web Game Development
                        </li>
                        <li>
                            AR/VR Solutions
                        </li>
                        <li>
                            Product Configurations
                        </li>
                        <li>
                            Enterprise Solutions
                        </li>
                        <li>
                            Broadcast & Streaming Solutions
                        </li>
                        <li>
                            Digital Showroom & Tours
                        </li>
                        <li>
                            Porting
                        </li>
                        <li>
                            Support & Maintenance
                        </li>

                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why TECHDEETS Unreal Engine Development Services?</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS Unreal Engine game app developers are here for you to build your dream product. Before choosing our services, let us tell you how we can help your project.
                    </p>

                    <h2 style={{ marginBottom: 20, }}>Interactive Designs:</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We use graphics and create a robust multiplayer framework. Our developers make everything look real and natural.
                    </p>
                    <h2 style={{ marginTop: 40 }}>Easy and Cost-Effective:</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Being one of the leading Unreal Engine game app development companies, TECHDEETS has made game development easy and affordable. Whatever your industry is, our experts can help you reach heights with their services.
                    </p>

                    <h2 style={{ marginBottom: 20, }}>Hassle-Free Solutions:</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, we have a team of Unreal Engine professionals to ensure ideal solutions. We focus on the problems that clients generally face and solve them.

                    </p>

                </div>



            </div>
        </div>
    )
}

export default ServiceGame;