import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { Card } from "react-bootstrap";

const BelieveThree = () => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">
                    <h2 style={{ marginBottom: 20 }}>Why Us?</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS stands as the best business service provider among many.
                        While many companies that offer the same services as us, we differ
                        because of the following qualities.
                    </p>
                    <br />
                    <h2 style={{ marginBottom: 20, marginTop: 60 }}>State-of-the-art Infrastructure</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TWith state-of-the-art infrastructure, cutting edge technology, and dedicated
                        staff, TECHDEETS delivers advanced solutions to critical issues.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Maintenance & Support</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS offers maintenance and support services after delivering the
                        project. We take care of all the issues so that you can stay relaxed.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>24/7 Monitoring</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TWe respond to critical issues before they impact your business, thus
                        ensuring enhanced productivity.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Reliable & Robust software Applications</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS team of experts has excellence and expertise in developing
                        the software applications that are reliable, robust, and accurate.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Shorter Turnaround</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS has experts who are specialised in various concerned areas.
                        As our experts share the work and do it as a team, the turnaround time is
                        shorter than the clients expect.
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Data Security</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>We help reduce your business risks through our data security management.
                        We ensure 100 per cent security for you!
                    </p>
                    <hr />
                    <h2 style={{ marginBottom: 20 }}>Affordable Pricing</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Pricing at TECHDEETS is affordable even to low-scale companies.
                        We value our clients over price.
                    </p>


                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">

                            {/* <SectionTitleTwo 
                                subTitle="Web design and digital marketing"
                                title="We think strategy, UX design, and web development"
                            /> */}

                            {/* <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                    onChange={onVisibilityChange}
                                                    offset={{ top: 10 }}
                                                    delayedCall
                                                    >
                                                    <CountUp end={didViewCountUp ? 110 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Happy Clients</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{ top: 10 }}
                                                delayedCall
                                                >
                                                <CountUp end={didViewCountUp ? 130 : 0} />
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">Completed projects</h6>
                                        <p>We help our clients increase profits by increasing their visibility online.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 me-0">
                            <div className="about-image js-tilt">

                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="about-image js-tilt">
                                {/* <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.jpg"} alt="" />
                                </Tilt> */}
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BelieveThree;

