import React from 'react'

import SectionTitle from '../../components/SectionTitles/SectionTitle';

const ServiceTest = () => {
    return (
        <div className="section section-padding-t90 section-padding-bottom-200">
            {/* <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}> */}
            <div className="container">

                <SectionTitle
                    // headingOption="fz-32"
                    title="Testing"
                // subTitle="We produce beautifully crafted creative solutions that transcend business goals.
                // We provide the exceptional service we’d want to experience ourselves!"
                />

            </div>
            <div className="container">
                <div className="row">
                    <h3 style={{ marginBottom: 20, marginTop: 50, color: "red" }}>Automation Testing</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS is providing a complete range of test automation services since many years, while automated (API and UI) functional and performance testing is one among them.
                    </p>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>From higher test coverage to better insights, automation testing has a lot of advantages. Hire a team of automating engineers from TECHDEETS to acquire the best services for reasonable price. </p>



                    <h3 style={{ marginTop: 60 }}>Why Automation?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Time-saving
                        </li>
                        <li>
                            Low business expenses
                        </li>
                        <li>
                            Reusability of the test suite
                        </li>
                        <li>
                            Better Insights
                        </li>
                        <li>
                            Great Accuracy
                        </li>
                        <li>
                            Improved Test Coverage
                        </li>
                        <li>
                            Incredible Capability
                        </li>



                    </ul>

                    <h3 style={{ marginTop: 60, }}>Automation Testing Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS provides a wide range of testing services that include:</p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Test environment setup and configuration
                        </li>
                        <li>
                            Automated test data generation
                        </li>
                        <li>
                            Automated UPI Testing
                        </li>
                        <li>
                            API Testing
                        </li>
                        <li>
                            Acceptance Testing
                        </li>


                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why TECHDEETS Automation Testing Services?</h2>

                    <h4 style={{ marginTop: 30, marginBottom: 20 }}>ISO Certified:</h4>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS is ISO certified for the quality management system and security. So, we ensure our clients excellent quality services and top security of customer information.
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>This is one of the reasons our clients repeatedly choose us over other automation service companies.</p>

                    <h2 style={{ marginBottom: 20 }}>Design & Technical Expertise:</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, we have test engineers who are well-versed with both design and technology. Their expertise made us get numerous testing projects to date.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Long-term Cooperation:</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>After handing over your project to you, we are always here to help with further issues. We believe in long-term association with our clients.
                    </p>


                    <h3 style={{ marginBottom: 20, marginTop: 50, color: "red" }}>Manual Testing</h3>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>While automation has gained popularity in recent years, manual testing hasn’t lost its craze because of its benefits. Even today, many prefer manual testing for their software.
                    </p>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS is popular for manual testing services as it has a team of veteran testing experts. They are excelled at performing manual testing and worked on various testing projects to date.</p>



                    <h3 style={{ marginTop: 60 }}>Why Manual?</h3>



                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            Identify critical defects
                        </li>
                        <li>
                            Leverages different techniques
                        </li>
                        <li>
                            Improved user experience
                        </li>
                        <li>
                            Great Usability
                        </li>
                        <li>
                            Less Investment
                        </li>
                        <li>
                            Real-time testing
                        </li>
                        <li>
                            Custom feedback
                        </li>



                    </ul>

                    <h3 style={{ marginTop: 60, }}>Manual Testing Services</h3>
                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS offers different manual testing services and here are a few of them:</p>

                    <ul style={{ fontSize: 20, fontWeight: 400, marginLeft: 40 }}>
                        <li>
                            nit Testing
                        </li>
                        <li>
                            Regression Testing
                        </li>
                        <li>
                            System Testing
                        </li>
                        <li>
                            API Testing
                        </li>
                        <li>  Compatibility Testing</li>
                        <li>  System Integration Testing (SIT)</li>
                        <li>  Testcase Creation Activity</li>
                        <li> Multiplatform Testing</li>
                        <li>Cross-browser Testing</li>
                        <li> Software Product Testing</li>
                        <li>Mobile App Testing</li>
                        <li> W3C/ HIG Compliance Testing</li>

                    </ul>
                    <h2 style={{ marginTop: 40 }}>Why TECHDEETS Manual Testing Services?</h2>

                    <h2 style={{ marginTop: 30, marginBottom: 20 }}>Team of Experts</h2>



                    <p style={{ fontSize: 20, fontWeight: 600, }}>TECHDEETS has experts who work on a wide range of applications that includes the web, desktop, mobile, and more. With many years of experience and expertise, our manual testing engineers identify and resolve even the critical bugs.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Organised Approach</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>At TECHDEETS, our professionals follow an organised approach for positive results. From having a meeting with clients to understanding their project to start working to delivering the project, they take care of everything.
                    </p>

                    <h2 style={{ marginBottom: 20 }}>Human-centric</h2>

                    <p style={{ fontSize: 20, fontWeight: 600, }}>Unlike automation testing, manual testing depends on human intelligence. Our team of testing engineers are pretty popular with handling complex projects.
                    </p>




                </div>



            </div>
        </div>
    )
}
export default ServiceTest;