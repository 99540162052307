import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import BelieveOne from '../container/WhatWeBelieve/BelieveOne';
import BelieveTwo from '../container/WhatWeBelieve/BelieveTwo';
import BelieveThree from '../container/WhatWeBelieve/BelieveThree';
import BelieveFive from '../container/WhatWeBelieve/BelieveFive';






const WhatWeBelieve = () => {
    return (
        <React.Fragment>
            <SEO title="Techdeets || WhatWeBelieve" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg.jpg"
                title="We are an agency located in Hyderabad"
                content="Home"
                contentTwo="What we Believe"
            />
            <BelieveOne />
            {/* <Video /> */}
            <BelieveTwo />
            <BelieveThree />
            {/* <BelieveFive /> */}
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            {/* <CallToActionTwo /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WhatWeBelieve;



